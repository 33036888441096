import React from "react";
import { Link } from "react-router-dom";
import { BaseLayout } from "components";
import classnames from "classnames";
import styles from "./styles.module.scss";
class Home extends React.PureComponent {
  render() {
     
    return (
      <BaseLayout>
        <div className={styles.list}>
           hello word
        </div>
      </BaseLayout>
    );
  }
}

export default Home;
