import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// 新手指南
import Guide from "pages/guide";
import list from "pages/list";
import detail from "pages/detail";
import notFound from "pages/notFound";
// 关于我们
import aboutUs from "pages/aboutUs";
import aboutUsJn from "pages/aboutUs/jn";
import Home from "pages/home";

import Help from "pages/help";
// 活动中心
import ActivityCenter from "pages/activityCenter";

import Beginner_Guide from "pages/beginner_guide";

import NewHelper from "pages/xinshou_help";
import Deposit_Guide from "pages/deposit_guide";
import Deposit_usdt_guide from "pages/deposit_usdt_guide";

import Detail_New from "pages/detail_new";
import Infor_Detail from "pages/inforDetail";
import Message_Details from "pages/messageDetails";
import Withdrawa_Guide from "pages/withdrawa_guide";
import Novice_Book from "pages/novice_book";
import Novice_Guide from "pages/novice_guide";

// 帮助中心
import Faq from "pages/faq";
import Cat from "pages/faq/cat";

import Category from "pages/category";

export default () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/detail/:id" component={detail} />
      <Route path="/list/:id" component={list} />
      <Route path="/guide" component={Guide} />
      <Route path="/about/jn" component={aboutUsJn} />
      <Route path="/about" component={aboutUs} />
      <Route path="/activity-center" component={ActivityCenter} />

      <Route path="/help/:id" component={Help} />

      {/* 新手指南 */}
      <Route path="/beginner_guide" component={Beginner_Guide} />
      <Route path="/newhelper" component={NewHelper} />
      {/* 存款指南 */}
      <Route path="/deposit_guide" component={Deposit_Guide} />
      {/* 指南问题详情 */}
      <Route path="/detail_new/:id" component={Detail_New} />
      {/* 资讯详情 */}
      <Route path="/infor_detail/:id" component={Infor_Detail} />
      {/* 系统消息详情 */}
      <Route path="/message_detail/:id" component={Message_Details} />
      {/* 取款须知 */}
      <Route path="/withdraw_guide" component={Withdrawa_Guide} />
      {/* 新手宝典 */}
      <Route path="/novice_book" component={Novice_Book} />
      <Route path="/novice_guide" component={Novice_Guide} />
      <Route path="/deposit_usdt_guide" component={Deposit_usdt_guide} />

      <Route path="/faq/:id" component={Faq} />
      <Route path="/cat/:id" component={Cat} />
      <Route path="/:category/:id" component={Category} />
      <Route path="*" component={notFound} />
    </Switch>
  </Router>
);
